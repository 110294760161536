import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import "./style.css";

const IntroTeaser = () => {
  return (
    <div className="introTeaser">
        <div className="introTeaserWrap">
            <div className="introTeaserLeft">
                <Text element="h3">SATI FOR ENTERPRISE</Text>
                <Text element="h1">Scalable application for large businesses where every client matters equally</Text>
                <Text element="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Text>
                <div className="introTeaserButtons">
                    <Button styling="primary">Contact Us</Button>
                    <Button styling="accent">Request Demo</Button>
                </div>
            </div>
            <div className="introTeaserRight">
                <img src="/enterprise-teaser.jpg" alt="img" />
            </div>
        </div>
    </div>
  );
};

export default IntroTeaser;
