import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import "./style.css";

const EnterpriseFeatures = () => {
    const iconRight = true ? (
        <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM23.5303 6.53033C23.8232 6.23744 23.8232 5.76257 23.5303 5.46967L18.7574 0.696701C18.4645 0.403807 17.9896 0.403807 17.6967 0.696701C17.4038 0.989594 17.4038 1.46447 17.6967 1.75736L21.9393 6L17.6967 10.2426C17.4038 10.5355 17.4038 11.0104 17.6967 11.3033C17.9896 11.5962 18.4645 11.5962 18.7574 11.3033L23.5303 6.53033ZM1 6.75L23 6.75L23 5.25L1 5.25L1 6.75Z" fill="#21174B" />
        </svg>
    ) : null;
    return (
        <div className="enterpriseFeatures">
            <div className="enterpriseFeaturesWrap">
                <span>Enterprise Features</span>
                <Text element="h1">Covering all enteprise fronts</Text>
            </div>
            <div className="enterpriseContent">
                <div className="enterpriseFeature">
                    <img src="/features-1.svg" alt="img" />
                    <Text element="h3">Scale across multiple companies</Text>
                    <Text element="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>
                    <Button styling="accent">Learn More{iconRight}</Button>
                </div>
                <div className="enterpriseFeature">
                    <img src="/features-2.svg" alt="img" />
                    <Text element="h3">Scale across multiple companies</Text>
                    <Text element="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>
                    <Button styling="accent">Learn More{iconRight}</Button>
                </div>
                <div className="enterpriseFeature">
                    <img src="/features-3.svg" alt="img" />
                    <Text element="h3">Scale across multiple companies</Text>
                    <Text element="p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>
                    <Button styling="accent">Learn More{iconRight}</Button>
                </div>
            </div>
        </div>
    );
};

export default EnterpriseFeatures;
