import React from "react";
import { useTranslation } from "react-i18next";
import CoreFeaturesIntro from "./CoreFeaturesIntro";
import CoreFeaturesSlider from "./CoreFeaturesSlider";
import Accordion from "./CoreFeaturesAccordion";
import { accordionData } from "./utils/content";
import "./style.css";

const CoreFeaturesTeaser = () => {
  const { t } = useTranslation();
  return (
    <div className="coreFeaturesTeaser">
        <img src="/features-eclipse.svg" className="eclipseBg" alt="eclipse" />
        <CoreFeaturesIntro />
        <CoreFeaturesSlider />
        <div className="accordion">
        {accordionData.map(({ title, content, expanded, id }) => (
          <Accordion key={id} title={title} content={content} expanded={expanded} />
        ))}
      </div>
    </div>
  );
};

export default CoreFeaturesTeaser;
