import React from "react";
import Text from "../../../../../Text";
import Button from "../../../../../Button";
import "./style.css";

const TeaserCard = (props) => (
  <div>
    <div
      className="teaserCard"
      style={{ backgroundColor: props.background === 'gray' ? 'rgb(120 144 156 / 10%)' : '#fff' }}
    >
      <img src={props.icon} className="teaserCard__icon" alt="card icon" />
      <Text element="h5" size="subheader">
        {props.title}
      </Text>
      <Text element="p">{props.text}</Text>
      <Button href={props.href} styling="accent" iconRight>Discover More</Button>
    </div>
  </div>
);
export default TeaserCard;
