import React from "react";
import Text from "../../../../../Text";
import "./style.css";

const CoreFeaturesIntro = () => {
  return (
    <div className="coreFeaturesIntro">
      <img src="/dashed-decoration.svg" alt="decoration" />
      <span>Core Features</span>
      <Text element="h1">Module-based Management System</Text>
      <Text element="p">
        SATIDESK Solution is a fully module-based Management System which consists of 12 different modules. By taking
        one of three versions (STANDARD version, PROFESSIONAL version, ENTERPRISE version) you choose which modules are
        enough to satisfy your business needs.
      </Text>
    </div>
  );
};

export default CoreFeaturesIntro;
