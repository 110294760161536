import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import { useTranslation } from "react-i18next";
import "./style.css";

const GuidesAndStudies = () => {
  const { t } = useTranslation();
  const iconRight = true ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      className="arrowIcon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        stroke="rgba(94, 53, 177, 1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke="rgba(94, 53, 177, 1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : null;
  return (
    <div className="containerWrapper w-100 guidesAndStudies">
      <img src="/purpleish-circle-2.svg" alt="" className="purpleCircle"/>
      <div className="container">
        <div className="guidesAndStudies">
          <div className="introductionText">
            <div className="title">Guides and case studies</div>
          </div>
          <div className="guides">
            <div className="guide">
              <img src="/picture-guide-1.png" alt="" className="guidePicture"/>
              <div className="guideTitle">Check out our ‘Learn’ section</div>
              <div className="guideDescription">A simple and intuitive user interface makes it easy to manage tasks. Simple forms and other user actions allow you to quickly use software to solve tasks.</div>
              <a href="/" className="guideLink">Discover more&nbsp;{iconRight}</a>
            </div>
            <div className="guide">
              <img src="/picture-guide-2.png" alt="" className="guidePicture"/>
              <div className="guideTitle">10 tips to get you organized</div>
              <div className="guideDescription">A simple and intuitive user interface makes it easy to manage tasks. Simple forms and other user actions allow you to quickly use software to solve tasks.</div>
              <a href="/" className="guideLink">Discover more&nbsp;{iconRight}</a>
            </div>
            <div className="guide">
              <img src="/picture-guide-3.png" alt="" className="guidePicture"/>
              <div className="guideTitle">Integrate SATI into your pipeline</div>
              <div className="guideDescription">A simple and intuitive user interface makes it easy to manage tasks. Simple forms and other user actions allow you to quickly use software to solve tasks.</div>
              <a href="/" className="guideLink">Discover more&nbsp;{iconRight}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidesAndStudies;
