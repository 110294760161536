import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import { useTranslation } from "react-i18next";
import CardsTeaser from "./CardsTeaser";
import "./style.css";

const ModularSystem = () => {
  const { t } = useTranslation();
  const iconRight = true ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="arrowIcon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : null;

  return (
      <div className="containerWrapper bg--purple-1 w-100 modular">
        <img src="/purpleish-circle.svg" alt="" className="purpleCircle"/>
        <div className="container">
          <div className="modularSystem">
            <div className="introductionText">
              <div className="title">Modular system to support all<br/>your company efforts</div>
              <div className="subTitle">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </div>
            </div>
            <div className="modularSystemBoxes">
              <div className="modularSystemBox">
                <div className="icon">
                  <img src="/icon-6x-grid-dark.svg" alt=""/>
                </div>
                <div className="title">Management</div>
                <div className="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <a className="arrowRight" href="">{iconRight}</a>
              </div>
              <div className="modularSystemBox">
                <div className="icon">
                  <img src="/icon-folder-dark.svg" alt=""/>
                </div>
                <div className="title">Projects</div>
                <div className="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <a className="arrowRight" href="">{iconRight}</a>
              </div>
              <div className="modularSystemBox">
                <div className="icon">
                  <img src="/icon-arrow-upwards-trend-dark.svg" alt=""/>
                </div>
                <div className="title">Statistics</div>
                <div className="description">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </div>
                <a className="arrowRight" href="">{iconRight}</a>
              </div>
              <div className="modularSystemBox double">
                <div className="doubleBox">
                  <div className="icon">
                    <img src="/icon-paper-dark.svg" alt=""/>
                  </div>
                  <div className="titleAndIcon">
                    <span className="title">Reports</span>
                    <a href="" className="arrowRight">
                      {iconRight}
                    </a>
                  </div>
                </div>
                <div className="doubleBox">
                  <div className="icon">
                    <img src="/icon-6x-grid-dark.svg" alt=""/>
                  </div>
                  <div className="titleAndIcon">
                    <span className="title">CMDB</span>
                    <a href="" className="arrowRight">
                      {iconRight}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <img className="selectedModulePicture" src="/statistics-dashboard.png" alt=""/>
          </div>
          <div className="fullControl">
            <div className="cards">
              <CardsTeaser/>
            </div>
            <a className="screenshotDemo" href="/" rel="noopener" target="_blank">
              <img src="/screenshot-demo.png" alt=""/>
            </a>
          </div>
        </div>
      </div>
  );
};

export default ModularSystem;
