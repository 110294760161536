import React, { Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SideBar from "./components/Header/Sidebar";
import Homepage from "./components/Pages/Home";
import Features from "./components/Pages/Features";
import Enterprise from "./components/Pages/Enterprise";
import Learn from "./components/Pages/Learn";
import Demo from "./components/Pages/Demo";
import PricingPage from "./components/Pages/Pricing";
import Contact from "./components/Pages/Contact";
import "./App.css";

function App() {
  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);
  return (
    <Router>
      <div className="app">
        <Suspense fallback={<div className="loading">Loading...</div>}>
          <SideBar
            pageWrapId={"appWrapper"}
            outerContainerId={"app"}
            right={true}
          />
          <Header />
          <div className="appWrap">
            <Routes>
              <Route exact={true} path="/" element={<Homepage />} />
              <Route path="/features" exact={true} element={<Features />} />
              <Route path="/enterprise" exact={true} element={<Enterprise />} />
              <Route path="/learn" exact={true} element={<Learn />} />
              <Route path="/demo" exact={true} element={<Demo />} />
              <Route path="/pricing" exact={true} element={<PricingPage />} />
              <Route path="/contact" exact={true} element={<Contact />} />
            </Routes>
          </div>
          <Footer />
        </Suspense>
      </div>
    </Router>
  );
}

export default App;
