import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import { useTranslation } from "react-i18next";
import "./style.css";

const GuidesAndStudies = () => {
  const { t } = useTranslation();
  const iconRight = true ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      className="arrowIcon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        stroke="rgba(94, 53, 177, 1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke="rgba(94, 53, 177, 1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : null;
  return (
    <div className="containerWrapper w-100 guideThroughTheProcess">
      <div className="container">
        <div className="guideThroughTheProcess">
          <div className="introductionText">
            <div className="title">Let us guide you through the process</div>
          </div>
          <div className="guides">
            <div className="guide">
              <img src="/icon-telephone-and-man.svg" alt="" className="guidePicture"/>
              <div className="guideTitle">Explore all features</div>
              <div className="guideDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
              <a href="/" className="guideLink">Discover more&nbsp;{iconRight}</a>
              <div className="count">
                <div className="orangeCircle">1</div>
              </div>
            </div>
            <div className="guide">
              <img src="/icon-folder-and-files.svg" alt="" className="guidePicture"/>
              <div className="guideTitle">Read through official guide</div>
              <div className="guideDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
              <a href="/" className="guideLink">Discover more&nbsp;{iconRight}</a>
              <div className="count">
                <div className="orangeCircle">2</div>
              </div>
            </div>
            <div className="guide">
              <img src="/icon-faq.svg" alt="" className="guidePicture"/>
              <div className="guideTitle">Visit FAQ page</div>
              <div className="guideDescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
              <a href="/" className="guideLink">Discover more&nbsp;{iconRight}</a>
              <div className="count">
                <div className="orangeCircle">3</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidesAndStudies;
