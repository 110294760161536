import React from "react";
import IntroTeaser from "./components/IntroTeaser";
import EnterpriseFeatures from "./components/EnterpriseFeatures";
import Security from "./components/Security";
import CTA from "./components/CTA";

const Enterprise = () => {
  return (
    <React.Fragment>
        <IntroTeaser />
        <EnterpriseFeatures />
        <Security />
        <CTA />
    </React.Fragment>
  );
};

export default Enterprise;
