import React, { Component } from "react";
import classNames from "classnames";

class Text extends Component {
  render() {
    const { element: Tag, size, font, children, ...props } = this.props;
    const classes = classNames({
      [`${props.className}`]: props.className,
      [`${size}--size`]: size,
      [`${font}--font`]: font,
    });
    return (
      <Tag {...props} className={classes}>
        {children}
      </Tag>
    );
  }
}

export default Text;
