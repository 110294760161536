import React from "react";
import "./style.css";

const Logo = (props) => {
  return (
    <a href={props.href}>
      <img className="footerLogo" src={props.src} alt={props.alt} />
    </a>
  );
};

export default Logo;
