import React from "react";
import Pricing from "../Home/components/Pricing";
import "./style.css";

const PricingPage = () => {
    return (
        <div className="pricingPage">
            <Pricing />
        </div>
    );
};

export default PricingPage;
