import React, { useState } from 'react';
import "./style.css";

const FAQAccordion = ({ title, content, description }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="accordion-learn-item">
            <div className={isActive ? 'accordion-learn-title accordion-learn-active' : 'accordion-learn-title'} onClick={() => setIsActive(!isActive)}>
                <div className="accordionLearnWrap">
                    <div className="accordion-learn-title-main">{title}</div>
                    <div className="accordion-learn-title-subtitle">{description}</div>
                </div>
                <div>
                    { isActive ? 
                    <img src="/accordion-arrow-up.svg" alt="arrow up" /> 
                    : 
                    <img src="/accordion-arrow-down.svg" alt="arrow down" /> }
                </div>
            </div>
            {isActive && <div className="accordion-learn-content">{content}</div>}
        </div>
    );
};

export default FAQAccordion;