import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

export default class CoreFeaturesSlider extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1
      };
      return (
        <div className="coreFeaturesSlider">
          <Slider {...settings}>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
            <div>
              <img src="/features-slider-img.png" alt="slider img" />
            </div>
          </Slider>
        </div>
      );
    }
  }
