import { React, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Text from "../../Text";
import "./style.css";

const Contact = () => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="contact">
      <div className="contactHeader">
        <div className="contactWrap">
          <Text element="h3">SUPPORT</Text>
          <Text element="h1">Contact Us</Text>
          <Text element="p">
            Are you an existing SATI user? Having issues with the app? Can’t find the answer you’re looking for? Fill up
            the form - we are ready to help
          </Text>
        </div>
      </div>
      <div className="contactWrap formWrap">
        <div className="contactForm">
          <Formik
            initialValues={{ email: "", firstName: "", lastName: "", toggle: false, checked: [], textarea: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={async (values) => {
              await new Promise((r) => setTimeout(r, 500));
              alert(JSON.stringify(values, null, 2));
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="nameWrap">
                  <label htmlFor="firstName">First Name*</label>
                  <Field id="firstName" name="firstName" />
                  <ErrorMessage name="firstName" component="div" />
                </div>
                <div className="lastNameWrap">
                  <label htmlFor="lastName">Last Name*</label>
                  <Field id="lastName" name="lastName" />
                  <ErrorMessage name="lastName" component="div" />
                </div>
                <div className="emailWrap">
                  <label htmlFor="email">Email address*</label>
                  <Field type="email" name="email" />
                  <ErrorMessage name="email" component="div" className="errMsg" />
                </div>
                <div className="topcWrap">
                  <label>Select topic:</label>
                  <div role="group" aria-labelledby="checkbox-group" className="topicGroup">
                    <label>
                      <Field type="checkbox" checked={isChecked} name="topic" value="Billing" />
                      Billing
                    </label>
                    <label>
                      <Field type="checkbox" checked={isChecked} name="topic" value="Managing projects" />
                      Managing projects
                    </label>
                    <label>
                      <Field type="checkbox" checked={isChecked} name="topic" value="User roles" />
                      User roles
                    </label>
                    <label>
                      <Field type="checkbox" checked={isChecked} name="topic" value="Updating CMDB" />
                      Updating CMDB
                    </label>
                    <label>
                      <Field type="checkbox" checked={isChecked} name="topic" value="Generating stats" />
                      Generating stats
                    </label>
                    <label>
                      <Field type="checkbox" checked={isChecked} name="topic" value="Trial" />
                      Trial
                    </label>
                    <label>
                      <Field type="checkbox" checked={isChecked} name="topic" value="Exporting reports" />
                      Exporting reports
                    </label>
                  </div>
                </div>
                <div className="topicWrap">
                  <label htmlFor="topic">Or choose your own topic*</label>
                  <Field type="email" name="email" />
                  <ErrorMessage name="email" component="div" />
                </div>
                <div className="moreWrap">
                  <label>Tell us more details*</label>
                  <Field component="textarea" name="textarea" />
                </div>
                <div className="validateInfo">Fields marked with * are required.</div>
                <label>
                  <Field type="checkbox" name="subscribe" value={false} />
                  YES - I want to receive SATI update emails. (You can cancel your subscription at any time.)
                </label>
                <div className="policy">
                  {/* By submitting my personal information, I understand and agree that SATI may collect, process, and
                  retain my data pursuant to the SATI Privacy Policy. */}
                </div>
                <button type="submit" disabled={isSubmitting}>
                  Send message
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <img src="/contact-ilustration.svg" alt="contact img" />
      </div>
    </div>
  );
};

export default Contact;
