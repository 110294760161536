import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import { useTranslation } from "react-i18next";
import "./style.css";

const MainTeaser = () => {
  const { t } = useTranslation();
  return (
      <div className="container">
        <div className="learnIntroTeaser">
          <div className="introductionText">
            <div className="">
              <div className="preTitle">
                SATI FEATURES
              </div>
              <div className="title">Get started with your SATI<br/>journey now!</div>
              <div className="subTitle">
                Read through our guides, documentation and FAQ and learn ho to use SATI service desk app as efficiently as possible.
                <br/>
                <br/>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.
              </div>
              <div className="ctaButtons">
                <Button href="/learn" styling="primary" className="tryForFreeBtn">Learn</Button>
              </div>
            </div>
          </div>
          <div className="introductionPicture">
            <img src="/learn-header-image.svg" alt="" className="learnHeaderPicture" />
          </div>
        </div>
      </div>
  );
};

export default MainTeaser;
