import React from "react";
import { useTranslation } from "react-i18next";
import Text from "../../../../Text";
import Button from "../../../../Button";
import "./style.css";

const RoleBasedTeaser = () => {
  const { t } = useTranslation();
  return (
    <div className="roleBasedTeaser">
      <img src="/dashed-decoration.svg" alt="decoration" className="decorationDashed" />
      <span>Core Features</span>
      <Text element="h1">Role-based access</Text>
      <Text element="p"></Text>
      <img src="/role-based-img.png" alt="img" />
      <img src="/role-based-img2.png" alt="img" className="roleBasedChecklist" />
      <div className="buttonWrap">
        <Button styling="accent">Try for free</Button>
      </div>
      <div className="decorationLine"></div>
    </div>
  );
};

export default RoleBasedTeaser;
