import React from "react";
import { useTranslation } from "react-i18next";
import TeaserIntro from "./TeaserIntro";
import TeaserImages from "./TeaserImages";
import "./style.css";

const MainTeaser = () => {
  const { t } = useTranslation();
  return (
      <div className="mainTeaser">
        <TeaserIntro />
        <TeaserImages />
      </div>
  );
};

export default MainTeaser;
