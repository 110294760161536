import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Button = (props) => {
  const iconLeft = props.iconLeft ? (
    <svg
      className="mailIcon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
        stroke="#FF6F61"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 6L12 13L2 6"
        stroke="#FF6F61"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : null;

  const iconRight = props.iconRight ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="arrowIcon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        stroke="#FF6F61"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke="#FF6F61"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : null;

  const classes = classNames({
    btn: true,
    [`btn--${props.styling}`]: props.styling,
  });

  const customClass = props.className ? props.className : "";
  return (
    <Link to={props.href}>
      <button className={classes + ' ' + customClass}>
        {iconLeft}
        {props.children}
        {iconRight}
      </button>
    </Link>
  );
};

export default Button;
