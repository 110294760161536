import React from "react";
import IntroTeaser from "./components/IntroTeaser";
import CardsTeaser from "./components/CardsTeaser";
import CoreFeaturesTeaser from "./components/CoreFeaturesTeaser";
import RoleBasedTeaser from "./components/RoleBasedTeaser";
import PricingTeaser from "./components/Pricing";
// import Testimonials from "./components/Testimonials";
import Partners from "./components/Partners";

const Homepage = () => {
  return (
    <React.Fragment>
      <IntroTeaser />
      <CardsTeaser />
      <CoreFeaturesTeaser />
      <RoleBasedTeaser />
      <PricingTeaser />
      {/* <Testimonials /> */}
      <Partners />
    </React.Fragment>
  );
};

export default Homepage;
