/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { slide as Menu } from "react-burger-menu";

export default (props) => {
  return (
    <Menu
      {...props}
      customBurgerIcon={<img src="/menu.svg" alt="Menu" />}
      customCrossIcon={<img src="/close.svg" alt="Close" />}
    >
      <a href="/">Home</a>
      <a href="/features">Features</a>
      <a href="/pricing">Pricing</a>
      <a href="/learn">Learn</a>
      {/* <a href="/enterprise">Enterprise</a> */}
      <a href="/contact">Contact</a>
    </Menu>
  );
};
