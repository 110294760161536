import React from "react";
import Logo from "./Logo";
import NavList from "./NavMenu";
import Button from "../Button";
import "./style.css";

const Header = () => {
  return (
      <header className="header">
        <div className="headerWrap">
          <Logo href="/" src="/logo.svg" alt="Logo" />
          <nav className="navigation">
            <NavList />
            <Button href="/login" className="loginBtn">
              Log In
            </Button>
            <Button href="/try-for-free" className="tryForFreeBtn" styling="primary">
              Try for free
            </Button>
          </nav>
        </div>
      </header>
  );
};

export default Header;
