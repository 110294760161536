import React from "react";
import NavMenu from "./NavMenu";
import NavItem from "./NavItem";
import "./style.css";

const NavList = () => {
  return (
    <NavMenu>
      <NavItem href="/">Home</NavItem>
      <NavItem href="/features">Features</NavItem>
      <NavItem href="/pricing">Pricing</NavItem>
      <NavItem href="/learn">Learn</NavItem>
      {/* <NavItem href="/enterprise">Enterprise</NavItem> */}
      <NavItem href="/contact">Contact</NavItem>
    </NavMenu>
  );
};

export default NavList;
