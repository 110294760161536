import React from "react";
import "./index.css";

const Demo = () => {
  return (
    <div className="containerWrapper demo">
      <img className="bg-circle" src="/purpleish-circle-2.svg"/>
      <img className="bg-circle-2" src="/purpleish-circle-2.svg"/>
      <div className="container">
        <h1 className="title mt-100px">Request free demo</h1>
        <div className="card mt-80px">
          <h3 className="number">1</h3>
          <div className="card-box">
            <label className="label">E-mail address*</label>
            <div className="mt-10px">
              <input type="text" className="custom-input" />
            </div>
            <div className="mt-10px">
              <input type="checkbox" id="vehicle1" className="checkbox" value="Bike" />
              <label htmlFor="vehicle1" className="label">Please also send me occasional emails about SATI updates.<br/>(You can cancel subscription at any time.)</label>
            </div>
            <div className="mt-20px">
              <p className="label">By submitting my personal information, I understand and agree that SATI may collect, process, and retain my data pursuant to the <a className="inline-href" href="/">SATI Privacy Policy</a>.</p>
            </div>
            <div className="button"><span>Next</span></div>
          </div>
        </div>
        <div className="card mt-80px">
          <h3 className="number">2</h3>
          <div className="card-box">
            <div className="row">
              <div className="col mb-30px mb-lg-0">
                <label className="label">First name*</label>
                <div className="mt-10px">
                  <input type="text" className="custom-input" />
                </div>
              </div>
              <div className="col">
                <label className="label">Last name</label>
                <div className="mt-10px">
                  <input type="text" className="custom-input" />
                </div>
              </div>
            </div>
            <div className="mt-30px">
              <label className="label">Phone Number*</label>
              <div className="mt-10px">
                <input type="text" className="custom-input" />
              </div>
              <div className="button"><span>Next</span></div>
            </div>
          </div>
        </div>
        <div className="card mt-80px">
          <h3 className="number">3</h3>
          <div className="card-box">
            <div className="row">
              <div className="col mb-30px mb-lg-0">
                <label className="label">Company name*</label>
                <div className="mt-10px">
                  <input type="text" className="custom-input" />
                </div>
              </div>
              <div className="col">
                <label className="label">Number of employees*</label>
                <div className="mt-10px">
                  <select type="text" className="">
                  <option value="" disabled selected></option>
                    <option value="1-5">1-5</option>
                    <option value="5-10">5-10</option>
                    <option value="10-50">10-50</option>
                    <option value="50-100">50-100</option>
                    <option value="100+">100+</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mt-30px">
              <label htmlFor="" className="label">What will you use SATI for?</label>
              <div className="mt-10px">
                <input type="radio" id="radio1" name="sati_reason_for_use" className="checkbox" value="managing_my_company_employees" />
                <label htmlFor="radio1" className="label">Managing my company employees</label>
              </div>
              <div className="mt-10px">
                <input type="radio" id="radio2" name="sati_reason_for_use" className="checkbox" value="outsourcing_work" />
                <label htmlFor="radio2" className="label">Outsourcing work</label>
              </div>
              <div className="mt-10px">
                <input type="radio" id="radio3" name="sati_reason_for_use" className="checkbox" value="managing_outside_issue_requests" />
                <label htmlFor="radio3" className="label">Managing outside issue requests</label>
              </div>
            </div>
            <div className="row mt-30px">
              <div className="col mb-30px mb-lg-0">
                <label className="label">Select SATI interface setup language</label>
                <div className="mt-10px">
                  <select name="" className="" id="">
                    <option value="" disabled selected></option>
                    <option value="en">English</option>
                    <option value="it">Italian</option>
                    <option value="bs">Bosnian</option>
                  </select>
                </div>
              </div>
              <div className="col">
                <label className="label">Create a password*</label>
                <div className="mt-10px">
                  <input type="password" className="custom-input" min="8" max="128" />
                </div>
              </div>
            </div>
            <div className="button"><span>Sign up</span></div>
            <p className="after-text mt-20px">By clicking “Sign up” you agree to the SATI Service Desk Subscription Agreement.</p>
          </div>
        </div>
        <div className="card special-card mt-80px mb-100px">
          <div className="text-center">
            <img src="/checkmark.svg" className="checkmarker" alt="" />
          </div>
          <div className="text-center mt-30px">
            <h2 className="sub-title">Your request has been successfully submitted!</h2>
          </div>
          <div className="text-center">
            <p className="sub-text mt-30px">Our team will reach out to you as soon as possible. Please make sure to check inbox and spam folder 1-3 work days after submitting request.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demo;
