import React from "react";
import FAQAccordion from "./FAQAccordion";
import { accordionData } from "./utils/content";
import "./index.css";

const FAQ = () => {
    return (
        <div className="container faq">
            <h2 className="title mt-120px">
                Frequently Asked Questions
            </h2>
            <div className="align-items-center mt-70px">
                <div className="c-row">
                    <input type="text" className="custom-input-learn" placeholder="Search help section..." />
                    <img className="custom-button" src="/search.svg" />
                </div>
            </div>
            <div className="mt-95px">
                <div className="a-row">
                    <div className="a-card"> 
                        <div className="a-card-box">
                            <img className="a-card-svg" src="/icon-credit-card-purple.svg" />
                            <p className="a-card-text mt-30px">Purchasing</p>
                        </div>
                    </div>
                    <div className="a-card"> 
                        <div className="a-card-box">
                            <img className="a-card-svg" src="/icon-user-purple.svg" />
                            <p className="a-card-text mt-30px">Account</p>
                        </div>
                    </div>
                    <div className="a-card"> 
                        <div className="a-card-box">
                            <img className="a-card-svg" src="/icon-file-add-purple.svg" />
                            <p className="a-card-text mt-30px">Exporting Files</p>
                        </div>
                    </div>
                    <div className="a-card"> 
                        <div className="a-card-box">
                            <img className="a-card-svg" src="/icon-penboard-purple.svg" />
                            <p className="a-card-text mt-30px">Projects</p>
                        </div>
                    </div>
                    <div className="a-card"> 
                        <div className="a-card-box">
                            <img className="a-card-svg" src="/icon-boxes-purple.svg" />
                            <p className="a-card-text mt-30px">Modules</p>
                        </div>
                    </div>
                    <div className="a-card"> 
                        <div className="a-card-box">
                            <img className="a-card-svg" src="/icon-inwards-link-purple.svg" />
                            <p className="a-card-text mt-30px">Importing Assets</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-row mt-180px mb-50px">
                <div className="p-text active">Purchasing</div>
                <div className="p-text">Account</div>
                <div className="p-text">Exporting Files</div>
                <div className="p-text">Projects</div>
                <div className="p-text">Modules</div>
                <div className="p-text">Importing Assets</div>
            </div>
            <div className="accordion mb-50px">
                {accordionData.map(({ title, description, content, expanded, id }) => (
                  <FAQAccordion key={id} title={title} description={description} content={content} expanded={expanded} />
                ))}
            </div>
            <h2 className="title mt-70px mb-50px">
                Download Official User Guide
            </h2>
            <div className="guide-buttons mb-150px">
                <div className="user-guide-button">
                    <img src="/icon-file-download.svg" alt=""/>
                    <img src="/icon-file-pdf.svg" alt=""/>
                    <div className="button-content">SATI User Guide (English)</div>
                </div>
                <div className="user-guide-button">
                    <img src="/icon-file-download.svg" alt=""/>
                    <img src="/icon-file-pdf.svg" alt=""/>
                    <div className="button-content">SATI User Guide (Bosnian)</div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
