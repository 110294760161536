import React from "react";
import Logo from "./Logo";
import Button from "../Button";
import "./style.css";

const Footer = () => {
  const iconRight = true ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      className="arrowIcon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 12H19" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 5L19 12L12 19" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ) : null;
  return (
    <footer className="footer">
      <div className="container">
        <div className="footerLeftSide">
          <div className="footerList" id="footerList1">
            <Logo href="/" src="/sati-logo-footer.svg" alt="Logo" />
            <div className="footerDescription">
              An easy to use service desk software with support for event, incident, problem and change management
            </div>
            <div className="footerAddress">
              Ismeta Alajbegovica Serbe 9
              <br />
              71210 Ilidža
              <br />
              Bosna i Hercegovina
            </div>
            <div className="footerPhone">+387 33 835-859</div>
            <div className="footerEmail">support@sati.com</div>
          </div>
          <div className="footerList" id="footerList2">
            <div className="footerSublist1" id="footerSublist1">
              <div className="footerListItem">
                <a href="/">Home</a>
              </div>
              <div className="footerListItem">
                <a href="/">Features</a>
              </div>
              <div className="footerListItem">
                <a href="/">Pricing</a>
              </div>
              <div className="footerListItem">
                <a href="/">Learn</a>
              </div>
              <div className="footerListItem">
                <a href="/">Enterprise</a>
              </div>
            </div>
            <div className="footerSublist2" id="footerSublist2">
              <div className="footerListItem">
                <a href="/">Documentation</a>
              </div>
              <div className="footerListItem">
                <a href="/">Tutorials</a>
              </div>
              <div className="footerListItem">
                <a href="/">FAQ</a>
              </div>
              <div className="footerListItem">
                <a href="/">Modules</a>
              </div>
            </div>
          </div>
          <div className="footerList" id="footerList3">
            <div className="footerListItem">
              <a href="/">Instagram</a>
            </div>
            <div className="footerListItem">
              <a href="/">Facebook</a>
            </div>
            <div className="footerListItem">
              <a href="/">Twitter</a>
            </div>
            <div className="footerListItem">
              <a href="/">LinkedIn</a>
            </div>
            <div className="footerListItem">
              <a href="/">Linktree</a>
            </div>
          </div>
        </div>
        <div className="footerRightSide">
          <div>
            <div className="footerTitle">Request demo</div>
            <div className="footerInput">
              <input type="email" placeholder="Enter your email address..." min="4" />
              <button type="submit">{iconRight}</button>
            </div>
            <div className="footerSocialTouch">We’ll get back in touch with you shortly!</div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footerBottom">
          <div className="copyright">© SATI d.o.o {new Date().getFullYear()}. All rights reserved.</div>
          <div className="metalinks">
            <a className="link" href="/">
              Support
            </a>
            <a className="link" href="/">
              Privacy Policy
            </a>
            <a className="link" href="/">
              Terms of Use
            </a>
            <a className="link" href="/">
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
