import React from "react";
import "./style.css";

const TeaserImages = () => {
  return (
    <div className="teaserImages">
      <img src="/macbook.png" className="hero1" alt="macbook" />
      <img src="/wave-background.svg" alt="background" className="hero2" />
      <img src="/checklist.svg" alt="checklist icon" className="checklistIcon" />
      <img src="/statistics.svg" alt="statistics icon" className="statisticsIcon" />
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="teaserPlusIcon1">
        <path d="M8.00001 15.9996C7.83193 16.0039 7.66487 15.9724 7.50981 15.9074C7.35475 15.8425 7.21521 15.7453 7.10039 15.6225C6.9806 15.5059 6.88621 15.3658 6.82317 15.211C6.76013 15.0562 6.7298 14.89 6.73408 14.7229V9.26594H1.26633C1.09961 9.27092 0.933665 9.24143 0.778868 9.17932C0.624072 9.11722 0.483759 9.02384 0.366709 8.90502C0.247026 8.78722 0.15277 8.64614 0.0897648 8.49047C0.0267595 8.33482 -0.00365623 8.16789 0.000396925 8.00001C-0.00388057 7.8329 0.0264539 7.66672 0.0894937 7.5119C0.152534 7.35708 0.246916 7.21698 0.366709 7.10039C0.483492 6.98086 0.623632 6.88665 0.778404 6.82363C0.933176 6.76061 1.09926 6.73012 1.26633 6.73408H6.73408V1.26633C6.73116 1.09928 6.76191 0.933355 6.8245 0.778446C6.88708 0.623537 6.98022 0.482818 7.09836 0.364679C7.2165 0.24654 7.35722 0.1534 7.51213 0.0908145C7.66704 0.0282288 7.83296 -0.00252162 8.00001 0.000396925C8.16712 -0.00388057 8.3333 0.0264539 8.48812 0.0894937C8.64294 0.152534 8.78304 0.246916 8.89963 0.366709C9.02022 0.482693 9.11514 0.622691 9.17823 0.777653C9.24133 0.932616 9.27121 1.09909 9.26594 1.26633V6.73408H14.7337C14.9003 6.73006 15.066 6.75999 15.2206 6.82204C15.3753 6.88409 15.5157 6.97696 15.6333 7.095C15.7538 7.21221 15.8486 7.35319 15.9116 7.50899C15.9747 7.66479 16.0047 7.83201 15.9996 8.00001C16.0018 8.16686 15.9705 8.33245 15.9077 8.48702C15.8448 8.64159 15.7517 8.78201 15.6337 8.9C15.5157 9.01799 15.3753 9.11115 15.2207 9.174C15.0661 9.23685 14.9005 9.26811 14.7337 9.26594H9.26594V14.7337C9.26886 14.9007 9.23811 15.0667 9.17552 15.2216C9.11294 15.3765 9.0198 15.5172 8.90166 15.6353C8.78352 15.7535 8.6428 15.8466 8.48789 15.9092C8.33298 15.9718 8.16706 16.0025 8.00001 15.9996Z" fill="#28DB24" />
      </svg>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="teaserPlusIcon2">
        <path d="M8.00001 15.9996C7.83193 16.0039 7.66487 15.9724 7.50981 15.9074C7.35475 15.8425 7.21521 15.7453 7.10039 15.6225C6.9806 15.5059 6.88621 15.3658 6.82317 15.211C6.76013 15.0562 6.7298 14.89 6.73408 14.7229V9.26594H1.26633C1.09961 9.27092 0.933665 9.24143 0.778868 9.17932C0.624072 9.11722 0.483759 9.02384 0.366709 8.90502C0.247026 8.78722 0.15277 8.64614 0.0897648 8.49047C0.0267595 8.33482 -0.00365623 8.16789 0.000396925 8.00001C-0.00388057 7.8329 0.0264539 7.66672 0.0894937 7.5119C0.152534 7.35708 0.246916 7.21698 0.366709 7.10039C0.483492 6.98086 0.623632 6.88665 0.778404 6.82363C0.933176 6.76061 1.09926 6.73012 1.26633 6.73408H6.73408V1.26633C6.73116 1.09928 6.76191 0.933355 6.8245 0.778446C6.88708 0.623537 6.98022 0.482818 7.09836 0.364679C7.2165 0.24654 7.35722 0.1534 7.51213 0.0908145C7.66704 0.0282288 7.83296 -0.00252162 8.00001 0.000396925C8.16712 -0.00388057 8.3333 0.0264539 8.48812 0.0894937C8.64294 0.152534 8.78304 0.246916 8.89963 0.366709C9.02022 0.482693 9.11514 0.622691 9.17823 0.777653C9.24133 0.932616 9.27121 1.09909 9.26594 1.26633V6.73408H14.7337C14.9003 6.73006 15.066 6.75999 15.2206 6.82204C15.3753 6.88409 15.5157 6.97696 15.6333 7.095C15.7538 7.21221 15.8486 7.35319 15.9116 7.50899C15.9747 7.66479 16.0047 7.83201 15.9996 8.00001C16.0018 8.16686 15.9705 8.33245 15.9077 8.48702C15.8448 8.64159 15.7517 8.78201 15.6337 8.9C15.5157 9.01799 15.3753 9.11115 15.2207 9.174C15.0661 9.23685 14.9005 9.26811 14.7337 9.26594H9.26594V14.7337C9.26886 14.9007 9.23811 15.0667 9.17552 15.2216C9.11294 15.3765 9.0198 15.5172 8.90166 15.6353C8.78352 15.7535 8.6428 15.8466 8.48789 15.9092C8.33298 15.9718 8.16706 16.0025 8.00001 15.9996Z" fill="#5E35B1" />
      </svg>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="teaserPlusIcon3">
        <path d="M8.00001 15.9996C7.83193 16.0039 7.66487 15.9724 7.50981 15.9074C7.35475 15.8425 7.21521 15.7453 7.10039 15.6225C6.9806 15.5059 6.88621 15.3658 6.82317 15.211C6.76013 15.0562 6.7298 14.89 6.73408 14.7229V9.26594H1.26633C1.09961 9.27092 0.933665 9.24143 0.778868 9.17932C0.624072 9.11722 0.483759 9.02384 0.366709 8.90502C0.247026 8.78722 0.15277 8.64614 0.0897648 8.49047C0.0267595 8.33482 -0.00365623 8.16789 0.000396925 8.00001C-0.00388057 7.8329 0.0264539 7.66672 0.0894937 7.5119C0.152534 7.35708 0.246916 7.21698 0.366709 7.10039C0.483492 6.98086 0.623632 6.88665 0.778404 6.82363C0.933176 6.76061 1.09926 6.73012 1.26633 6.73408H6.73408V1.26633C6.73116 1.09928 6.76191 0.933355 6.8245 0.778446C6.88708 0.623537 6.98022 0.482818 7.09836 0.364679C7.2165 0.24654 7.35722 0.1534 7.51213 0.0908145C7.66704 0.0282288 7.83296 -0.00252162 8.00001 0.000396925C8.16712 -0.00388057 8.3333 0.0264539 8.48812 0.0894937C8.64294 0.152534 8.78304 0.246916 8.89963 0.366709C9.02022 0.482693 9.11514 0.622691 9.17823 0.777653C9.24133 0.932616 9.27121 1.09909 9.26594 1.26633V6.73408H14.7337C14.9003 6.73006 15.066 6.75999 15.2206 6.82204C15.3753 6.88409 15.5157 6.97696 15.6333 7.095C15.7538 7.21221 15.8486 7.35319 15.9116 7.50899C15.9747 7.66479 16.0047 7.83201 15.9996 8.00001C16.0018 8.16686 15.9705 8.33245 15.9077 8.48702C15.8448 8.64159 15.7517 8.78201 15.6337 8.9C15.5157 9.01799 15.3753 9.11115 15.2207 9.174C15.0661 9.23685 14.9005 9.26811 14.7337 9.26594H9.26594V14.7337C9.26886 14.9007 9.23811 15.0667 9.17552 15.2216C9.11294 15.3765 9.0198 15.5172 8.90166 15.6353C8.78352 15.7535 8.6428 15.8466 8.48789 15.9092C8.33298 15.9718 8.16706 16.0025 8.00001 15.9996Z" fill="#FF8A00" />
      </svg>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="teaserPlusIcon4">
        <path d="M8.00001 15.9996C7.83193 16.0039 7.66487 15.9724 7.50981 15.9074C7.35475 15.8425 7.21521 15.7453 7.10039 15.6225C6.9806 15.5059 6.88621 15.3658 6.82317 15.211C6.76013 15.0562 6.7298 14.89 6.73408 14.7229V9.26594H1.26633C1.09961 9.27092 0.933665 9.24143 0.778868 9.17932C0.624072 9.11722 0.483759 9.02384 0.366709 8.90502C0.247026 8.78722 0.15277 8.64614 0.0897648 8.49047C0.0267595 8.33482 -0.00365623 8.16789 0.000396925 8.00001C-0.00388057 7.8329 0.0264539 7.66672 0.0894937 7.5119C0.152534 7.35708 0.246916 7.21698 0.366709 7.10039C0.483492 6.98086 0.623632 6.88665 0.778404 6.82363C0.933176 6.76061 1.09926 6.73012 1.26633 6.73408H6.73408V1.26633C6.73116 1.09928 6.76191 0.933355 6.8245 0.778446C6.88708 0.623537 6.98022 0.482818 7.09836 0.364679C7.2165 0.24654 7.35722 0.1534 7.51213 0.0908145C7.66704 0.0282288 7.83296 -0.00252162 8.00001 0.000396925C8.16712 -0.00388057 8.3333 0.0264539 8.48812 0.0894937C8.64294 0.152534 8.78304 0.246916 8.89963 0.366709C9.02022 0.482693 9.11514 0.622691 9.17823 0.777653C9.24133 0.932616 9.27121 1.09909 9.26594 1.26633V6.73408H14.7337C14.9003 6.73006 15.066 6.75999 15.2206 6.82204C15.3753 6.88409 15.5157 6.97696 15.6333 7.095C15.7538 7.21221 15.8486 7.35319 15.9116 7.50899C15.9747 7.66479 16.0047 7.83201 15.9996 8.00001C16.0018 8.16686 15.9705 8.33245 15.9077 8.48702C15.8448 8.64159 15.7517 8.78201 15.6337 8.9C15.5157 9.01799 15.3753 9.11115 15.2207 9.174C15.0661 9.23685 14.9005 9.26811 14.7337 9.26594H9.26594V14.7337C9.26886 14.9007 9.23811 15.0667 9.17552 15.2216C9.11294 15.3765 9.0198 15.5172 8.90166 15.6353C8.78352 15.7535 8.6428 15.8466 8.48789 15.9092C8.33298 15.9718 8.16706 16.0025 8.00001 15.9996Z" fill="#28DB24" />
      </svg>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="teaserPlusIcon5">
        <path d="M8.00001 15.9996C7.83193 16.0039 7.66487 15.9724 7.50981 15.9074C7.35475 15.8425 7.21521 15.7453 7.10039 15.6225C6.9806 15.5059 6.88621 15.3658 6.82317 15.211C6.76013 15.0562 6.7298 14.89 6.73408 14.7229V9.26594H1.26633C1.09961 9.27092 0.933665 9.24143 0.778868 9.17932C0.624072 9.11722 0.483759 9.02384 0.366709 8.90502C0.247026 8.78722 0.15277 8.64614 0.0897648 8.49047C0.0267595 8.33482 -0.00365623 8.16789 0.000396925 8.00001C-0.00388057 7.8329 0.0264539 7.66672 0.0894937 7.5119C0.152534 7.35708 0.246916 7.21698 0.366709 7.10039C0.483492 6.98086 0.623632 6.88665 0.778404 6.82363C0.933176 6.76061 1.09926 6.73012 1.26633 6.73408H6.73408V1.26633C6.73116 1.09928 6.76191 0.933355 6.8245 0.778446C6.88708 0.623537 6.98022 0.482818 7.09836 0.364679C7.2165 0.24654 7.35722 0.1534 7.51213 0.0908145C7.66704 0.0282288 7.83296 -0.00252162 8.00001 0.000396925C8.16712 -0.00388057 8.3333 0.0264539 8.48812 0.0894937C8.64294 0.152534 8.78304 0.246916 8.89963 0.366709C9.02022 0.482693 9.11514 0.622691 9.17823 0.777653C9.24133 0.932616 9.27121 1.09909 9.26594 1.26633V6.73408H14.7337C14.9003 6.73006 15.066 6.75999 15.2206 6.82204C15.3753 6.88409 15.5157 6.97696 15.6333 7.095C15.7538 7.21221 15.8486 7.35319 15.9116 7.50899C15.9747 7.66479 16.0047 7.83201 15.9996 8.00001C16.0018 8.16686 15.9705 8.33245 15.9077 8.48702C15.8448 8.64159 15.7517 8.78201 15.6337 8.9C15.5157 9.01799 15.3753 9.11115 15.2207 9.174C15.0661 9.23685 14.9005 9.26811 14.7337 9.26594H9.26594V14.7337C9.26886 14.9007 9.23811 15.0667 9.17552 15.2216C9.11294 15.3765 9.0198 15.5172 8.90166 15.6353C8.78352 15.7535 8.6428 15.8466 8.48789 15.9092C8.33298 15.9718 8.16706 16.0025 8.00001 15.9996Z" fill="#5E35B1" />
      </svg>
    </div>
  );
};

export default TeaserImages;
