import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import { useTranslation } from "react-i18next";
import "./style.css";

const PricingTeaser = () => {
  const { t } = useTranslation();
  return (
    <div className="pricing">
      <div className="pricingIntro">
        <span>Pricing</span>
        <Text element="h1">Choose the right plan for your business!</Text>
        <Text element="p">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.
        </Text>
        <img src="/dashed-decoration.svg" alt="decoration" className="" />
      </div>
      <div className="pricingContent">
        <div className="pricingCommercial">
          <Button styling="primary" className="commercialBtn">
            SATIDESK STANDARD
          </Button>
          <div className="pricingPrice">€15</div>

          <ul>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              User Module
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Event Management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Incident Management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Request fulfillment management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Problem management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Change management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Statistics module
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Reports module
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              CMDB management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Project management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Service catalog
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              SLA management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Vendor agreement management
            </li>
          </ul>
          <Button styling="primary" className="buyBtn">
            Buy
          </Button>
        </div>

        <div className="pricingCommercial">
          <Button styling="primary" className="commercialBtn">
            SATIDESK PROFESSIONAL
          </Button>
          <div className="pricingPrice">€25</div>
          <ul>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              User Module
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Event Management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Incident Management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Request fulfillment management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Problem management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Change management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Statistics module
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Reports module
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              CMDB management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Project management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Service catalog
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              SLA management
            </li>
            <li>
              <img src="/fail-icon.svg" alt="fail icon" className="pricingIcon" />
              Vendor agreement management
            </li>
          </ul>
          <Button styling="primary" className="buyBtn">
            Buy
          </Button>
        </div>

        <div className="pricingEnterprise">
          <Button styling="accent" className="enterpriseBtn">
            SATIDESK ENTERPRISE
          </Button>
          <div className="pricingPrice">€35</div>
          <ul>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              User Module
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Event Management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Incident Management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Request fulfillment management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Problem management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Change management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Statistics module
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Reports module
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              CMDB management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Project management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Service catalog
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              SLA management
            </li>
            <li>
              <img src="/check-icon.svg" alt="check icon" className="pricingIcon" />
              Vendor agreement management
            </li>
          </ul>
          <Button styling="primary" className="buyBtn">
            Buy
          </Button>
        </div>
      </div>
      <img src="/pricing-eclipse.svg" className="eclipseDecor" alt="eclipse" />
      <img src="/pricing-decor.svg" className="pricingDecor" alt="decor" />
      <img src="/pricing-decor.png" className="pricingDecorBox" alt="decor" />
    </div>
  );
};

export default PricingTeaser;
