import React from "react";
import IntroTeaser from "./components/IntroTeaser";
import ModularSystem from "./components/ModularSystem";
import ThreeFeatures from "./components/ThreeFeatures";
import GuidesAndStudies from "./components/GuidesAndStudies";
import "./index.css";

const Features = () => {
    return (
        <React.Fragment>
            <IntroTeaser />
            <ModularSystem />
            <ThreeFeatures />
            <GuidesAndStudies />
        </React.Fragment>
    );
};

export default Features;
