import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import "./style.css";

const CTA = () => {
  return (
    <div className="cta">
        <div className="ctaWrap">
            <div className="ctaLeft">
                <Text element="h1">If you are a larger scale business, contact us and start expanding even more</Text>
            </div>
            <div className="ctaRight">
                <Button className="btnContact">Contact Us</Button>
                <Button className="btnDemo">Request Demo</Button>
            </div>
        </div>
    </div>
  );
};

export default CTA;
