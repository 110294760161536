import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = (props) => {
  return (
    <li>
      <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={props.href} end>
        {props.children}
      </NavLink>
    </li>
  );
};

export default NavItem;
