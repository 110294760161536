import React from "react";
import Text from "../../../../../Text";
import TeaserCard from "./TeaserCard";
import "./style.css";

const CardsTeaser = () => {
    return (
        <div className="modularSystemCardsTeaserWrap">
            <Text element="h1">Full control over your projects</Text>
            <Text element="p" className="modularSystemCardsTeaserInfo">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Text>
            <div className="modularSystemCardsTeaser">
                <TeaserCard
                    classes="teaserCardModularSystems ml-lg-0 mr-lg-15 mb-30"
                    title="Assign Users"
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    href="/"
                    icon="/icon-user-orange.svg"
                />
                <TeaserCard
                    classes="teaserCardModularSystems mb-30"
                    title="Achieve Milestones"
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    href="/"
                    icon="/icon-flag-purple.svg"
                />
                <TeaserCard
                    classes="teaserCardModularSystems mr-lg-0 ml-lg-15 mb-30"
                    title="Create Reports"
                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    href="/"
                    icon="/icon-file-green.svg"
                />
            </div>
        </div>
    );
};

export default CardsTeaser;
