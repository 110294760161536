import React from "react";
import Text from "../../../../Text";
import TeaserCard from "./TeaserCard";
import "./style.css";

const CardsTeaser = () => {
  return (
    <div className="cardsTeaserWrap">
      <Text element="h1">Key Features</Text>
      <Text element="p" className="cardsTeaserInfo">
        Different business and technical catalogs Defined SLA agreements between service providers and its customers
        Categorization, Definition, Prioritization, and Resolution (tasks, incidents, user requests, problems,
        organization's goals transition and transformation, processes and technologies) using management module change /
        risk assessment reviews to risk treatment plans creation / organization projects definition and management /
        organizational assets recording and making relationships by creating CMDB database / performance measurement of
        business processes{" "}
      </Text>
      <div className="cardsTeaser">
        <TeaserCard
          title="Speeds Up Task Management"
          text="With the SATIDESK solution, on time, efficient, and fast project delivery for you and your company is guaranteed. SATIDESK will save you resources, time, and money."
          href="/technology-and-consulting"
          background="gray"
          icon="/teaser-icon1.svg"
        />
        <TeaserCard
          title="User-Experience Focused"
          text="SATIDESK looks to understand users needs, abilities, limitations, and goals. It is tailored to be user-experience focused."
          href="/user-experience-focused"
          icon="/teaser-icon2.png"
        />
        <TeaserCard
          title="Easy To Use"
          text="Easy to manage with events, incidents, user requests, problems, changes, SLA and vendor contracts, assets, projects and risks in a very well designed interface by using the SATIDESK solution."
          href="/easy-to-work-with"
          icon="/teaser-icon3.svg"
          background="gray"
        />
      </div>
    </div>
  );
};

export default CardsTeaser;
