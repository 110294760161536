import React from "react";
import IntroTeaser from "./components/IntroTeaser";
import GuidesAndStudies from "./components/GuidesAndStudies";
import VideoCourse from "./components/VideoCourse";
import FAQ from "./components/FAQ";
import "./index.css";

const Learn = () => {
    return (
        <React.Fragment>
            <IntroTeaser />
            <GuidesAndStudies />
            <VideoCourse />
            <div className="position-relative">
                <img src="/purpleish-circle-2.svg" alt="" className="purpleCircleSpecial"/>
            </div>
            <FAQ />
        </React.Fragment>
    );
};

export default Learn;
