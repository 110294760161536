import React from "react";
import { useTranslation } from "react-i18next";
import Text from "../../../../Text";
import "./style.css";

const Partners = () => {
    const { t } = useTranslation();
    return (
        <div className="partners">
            <Text element="h3">Trusted by reliable partners and happy customers</Text>
            <div className="partnersContent">
                <div className="partnersItem">
                    <img src="/elektroprivreda-logo.png" className="partnerImg" alt="partnerImg" />
                </div>
                <div className="partnersItem">
                    <img src="/bht-logo.png" className="partnerImg" alt="partnerImg" />
                </div>
                <div className="partnersItem">
                    <img src="/sarajevogas-logo.png" className="partnerImg" alt="partnerImg" />
                </div>
                <div className="partnersItem">
                    <img src="/elektroprivreda-logo.png" className="partnerImg" alt="partnerImg" />
                </div>
                <div className="partnersItem">
                    <img src="/bht-logo.png" className="partnerImg" alt="partnerImg" />
                </div>
                <div className="partnersItem">
                    <img src="/sarajevogas-logo.png" className="partnerImg" alt="partnerImg" />
                </div>
            </div>
        </div>
    );
};      

export default Partners;

