import React from "react";
import Text from "../../../../Text";
import Button from "../../../../Button";
import { useTranslation } from "react-i18next";
import "./style.css";

const VideoCourse = () => {
  const { t } = useTranslation();
  const iconRight = true ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className="arrowIcon"
      fill="#5E35B1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
    >
      <path
        d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
        stroke="#5E35B1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : null;
  return (
      <div className="containerWrapper w-100 videoCourse">
        <div className="container">
          <div className="introductionText">
            <div className="title">Watch our video course</div>
            <div className="subTitle">
              If you feel intimidated by many features that SATI offers, watch our video course below for better learning experience. We will take you through the whole process of setting it upand  integrating it into your business.
            </div>
          </div>
        </div>
        <div className="container mainContent">
          <div className="screenshotCreateReport" href="">
            <video controls poster="/video-poster.svg">
              <source src="videoplayback.mp4" type="video/mp4"/>
            </video>
          </div>
          <div className="videoCourseCards">
            <div className="videoCourseCard">
              <div className="title">Role assignment tutorials</div>
              <div className="description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore...
              </div>
              <a className="arrowRight" href="">Learn more&nbsp;&nbsp;{iconRight}</a>
            </div>
            <div className="videoCourseCard">
              <div className="title">Creating and exporting reports</div>
              <div className="description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore...
              </div>
              <a className="arrowRight" href="">Learn more&nbsp;&nbsp;{iconRight}</a>
            </div>
            <div className="videoCourseCard">
              <div className="title">Managing CMDB items</div>
              <div className="description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore...
              </div>
              <a className="arrowRight" href="">Learn more&nbsp;&nbsp;{iconRight}</a>
            </div>
          </div>
        </div>
      </div>
  );
};

export default VideoCourse;
