export const accordionData = [
  {
    id: 1,
    title: "User Module",
    content: `Reporting problems, active and history requests, solution confirmations.`,
  },
  {
    id: 2,
    title: "Request Fulfillment",
    content: `Managing service requests through their lifecycle, from the initial raising of the request through to its closure`,
  },
  {
    id: 3,
    title: "Statistics Module",
    content: `Each user can see statistics showing how many requests have been resolved, currently active and terminated`,
  },
  {
    id: 4,
    title: "Project Management",
    content: `Project creation, adding members to project, creating milestones, tasks, gantts diagrams.`,
  },
  {
    id: 5,
    title: "Service Catalog Management",
    content: `Providing and maintaining a single sources consistent information on all operational services to ensure it is widely available to those with authorized access`,
  },
  {
    id: 6,
    title: "Event Management",
    content: `Helping organizers to plan, executive, and report on events - spearheading success for their business`,
  },
  {
    id: 7,
    title: "Problem Management",
    content: `Problem management is resolving applications with "Problem" classification.`,
    expanded: true,
  },
  {
    id: 8,
    title: "CMDB Management",
    content: `Valuable repository that stores lists of information and relationships`,
    expanded: true,
  },
  {
    id: 9,
    title: "SLA Management",
    content: `A contract between a service provider and its customers that documents what services the provider will furnish and defines the service standards the provider is obligated to meet`,
    expanded: true,
  },
  {
    id: 10,
    title: "Incident Management",
    content: `Series of steps taken to identify, analyze, and resolve critical incidents identified by users.`,
  },
  {
    id: 11,
    title: "Change Management",
    content: `Process of planning, implementing, and solidifying changes in an organization`,
  },
  {
    id: 12,
    title: "Reports Module",
    content: `Making different reports for service catalogs, incidents, problems, changes, requests, projects, assets.`,
  },
];
